<template>
  <CasualCouriersBase :loader_prop="loader_prop">
    <div
      class="package_section package_validation_section"
      v-if="box_screen == true"
    >
      <div
        class="package_section package_validation_section"
        v-if="im_here == false"
      >
        <div class="container">
          <div class="table_wrap">
            <div class="cell_wrap">
              <div class="empty_package">
                <img
                  src="./casuals_couriers/images/thankyou_page_box.png"
                  alt="Package Empty"
                />
                <p>{{ box_number }} מספר simpo אנא אתרו את תיבת</p>
                <br />
                <!-- <a href="#" class="comman_anchor" @click = "here">לחצו לפתיחת התיבה</a> -->
                <a href="#" @click="point_open_box_change_satus">
                  <div class="open_box_circel">
                    <h2>פתיחת תיבה</h2>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- <div class="package_section package_validation_section" v-if = "im_here == true"> -->
    <!-- <div class="container">
                <div class="table_wrap">
                    <div class="cell_wrap">
                        <a href="#" @click = "point_open_box_change_satus">
                            <div class="open_box_circel" >
                                <h2>פתיחת תיבה</h2>
                            </div>
                        </a>
                    </div>
                </div>
            </div> -->
    <!-- </div> -->

    <div
      class="package_section receving_packages_close_box"
      v-if="close_screen == true"
    >
      <div class="container">
        <div class="table_wrap">
          <div class="cell_wrap">
            <div class="empty_package">
              <img
                src="./casuals_couriers/images/thankyou_page_box.png"
                alt="Thank You"
              />
              <p>אנא סגרו את תיבה מספר {{ box_number }}</p>
              <a
                href="/casual_couriers/thanks"
                class="comman_anchor"
                @click="point_close_box"
                >סגרתי את התיבה</a
              >
              <span>שימו לב! לאחר סגירת התיבה לא ניתן לפתוח אותה בשנית</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </CasualCouriersBase>
</template>

<script>
import BackendModel from "../../models/BackendModel";
// import CasualCouriersBase from "./CasualCouriersBase";
import("./casuals_couriers/css/style.css");

export default {
  name: "CasualCouriersPackageValidation",
  components: {},
  data() {
    return {
      view_data: [],
      im_here: false,
      box_screen: true,
      close_screen: false,
      loader_prop: true,
      box_id: "",
      box_number: "",
    };
  },
  async mounted() {
    this.box_id = this.$route.query.box_id;
    this.box_number = this.$route.query.box_number;
    this.loader_prop = false;
  },

  methods: {
    here() {
      this.im_here = true;
      this.box_screen = false;
    },
    async point_open_box_change_satus() {
      this.loader_prop = true;
      let backendModel = new BackendModel();
      let res = await backendModel.CasualCourierRequest(
        "/Api/service/backoffice/casual_courier_open_box_change_satus",
        { box_id: this.box_id }
      );
      if (res.data.res == "OK") {
        this.loader_prop = false;
        this.im_here = true;
        this.close_screen = true;
      }
    },

    async point_close_box() {
      let backendModel = new BackendModel();
      let res = await backendModel.CasualCourierRequest(
        "/Api/service/backoffice/casual_courier_close_box",
        { box_id: this.box_id }
      );
      if (res == "OK") {
        console.log("cxvcbvn");
        console.log(res);
        await this.$router.push({ path: "/casual_couriers/thanks" });
      }
    },
  },
};
</script>

<style>
@import "./casuals_couriers/css/style.css";
</style>
